import { cache, useMemo } from 'react';

import { createBrowserClient } from "@supabase/ssr";
import { env } from '@/env.mjs';

export const getSupabaseBrowserClient = () => {
  return createBrowserClient(
    env.NEXT_PUBLIC_SUPABASE_URL,
    env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
  );
}

export function useSupabaseClient() {
  return useMemo(getSupabaseBrowserClient, []);
}
