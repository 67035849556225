
import MagicIcon from '~icons/mdi/magic-staff.jsx';
export default function GradientButton(props: {
    children: React.ReactNode;
    onClick?: () => void;
    icon?: React.ReactNode;
    style?: any;
}) {
  return (
    <button 
      className="h-[45px] w-full flex items-center justify-center rounded-md bg-gradient-to-r from-[#E74694] to-[#FFCD83] px-3 hover:opacity-90 transition-opacity"
      type="button"
      onClick={props.onClick}
      style={props.style ? props.style : {}}
    >
      {
        props.icon ? (
          <span className="mr-2">
            {props.icon}
          </span>
        ) : <MagicIcon className="w-6 h-6 text-white" />
      }
        
      <span className="ml-2 text-[14px] font-bold text-white">
        {props.children}
      </span>
    </button>
  )
}

